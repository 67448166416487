@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Segoe UI", Arial, sans-serif;
}

.funder {
  max-height: 100px;
}

button[disabled] {
  cursor: not-allowed;
}
.accessible-font {
  font-family: "Helvetica", Arial, sans-serif;
  font-weight: 800;
}

html {
  overflow: visible !important;
}
